import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from "../components/Layout";
import ContentfulRichText from "../components/ContentfulRichText";

export default function HomePage() {
  const data = useStaticQuery(graphql`
    {
      allContentfulBasicPage(filter: {slug: {eq: "/"}}) {
        nodes {
          headline
          body {
            raw
          }
          seoDescription
          heroImage {
            description
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);
  const node = data.allContentfulBasicPage.nodes[0];

  return (
    <Layout
      title={node.headline}
      description={node.seoDescription}
      image={node?.heroImage?.localFile?.publicURL}
    >
      <h1>{node.headline}</h1>
      {/* https://github.com/gatsbyjs/gatsby/issues/33871 */}
      <GatsbyImage
        image={getImage(node?.heroImage?.localFile?.childImageSharp?.gatsbyImageData)}
        alt={node.heroImage.description}
      />
      <ContentfulRichText text={JSON.parse(node.body.raw)} />
    </Layout>
  )
}
